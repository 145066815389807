
import Vue from "vue";
export default Vue.extend({
  name: "RandomColorWidget",
  data: () => ({ backgroundColor, color }),
  beforeCreate() {
    backgroundColor = "#" + Math.floor(Math.random() * 0xffffff).toString(16);
    color = `color-contrast(${backgroundColor} vs black, white)`;
  },
});
let backgroundColor: String;
let color: String;
