
import Vue from "vue";
import Forecast from "./Forecast.vue";
let forecasts: any[];

export default Vue.extend({
  name: "DailyWeatherWidget",
  components: { Forecast },
  data: () => ({ forecasts }),
  mounted() {
    (async () => {
      const url = `https://api.open-meteo.com/v1/forecast?latitude=47.6833&longitude=17.6351&daily=weathercode&timezone=UTC`;
      const daily = await fetch(url)
        .then((res) => res.json())
        .then((res) => res.daily);
      this.forecasts = daily.time.slice(0, 5).map((time: any, i: number) => ({
        time,
        weatherCode: daily.weathercode[i],
      }));
      console.log("forecasts", this.forecasts);
    })();
  },
});
