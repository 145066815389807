
import Vue from "vue";
import { codes } from "../weatherCodes";

export default Vue.extend({
  name: "Forecast",
  data: () => ({ codes, dayOfWeek }),
  props: ["forecast"],

  beforeMount() {
    this.dayOfWeek = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
    }).format(new Date(this.forecast.time));
  },
});

let dayOfWeek: any;
