export const codes: string[] = [];
codes[0] = "Tiszta égbolt";
codes[1] = "Főként tiszta";
codes[2] = "Részben felhős";
codes[3] = "Borús";
codes[45] = "Ködös";
codes[48] = "Zúzmarás köd";
codes[51] = "Gyenge szitálás";
codes[53] = "Mérsékelt szitálás";
codes[55] = "Sűrű szitálás";
codes[56] = "Gyenge fagyos szitálás";
codes[57] = "Sűrű fagyos szitálás";
codes[61] = "Gyenge eső";
codes[63] = "Mérsékelt eső";
codes[65] = "Heves eső";
codes[66] = "Gyenge fagyos eső";
codes[67] = "Heves fagyos eső";
codes[71] = "Gyenge hóesés";
codes[73] = "Mérsékelt hóesés";
codes[75] = "Heves hóesés";
codes[77] = "Jég";
codes[80] = "Gyenge záporeső";
codes[81] = "Mérsékelt záporeső";
codes[82] = "Heves záporeső";
codes[85] = "Gyenge hózápor";
codes[86] = "Heves hózápor";
codes[95] = "Zivatar";
codes[96] = "Enyhe jégesővel kísért zivatar";
codes[99] = "Heves jégesővel kísért zivatar";
